<template>
  <div>
    <AssisstantBar />
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูล ผู้ทรงคุณวุฒิอื่นจากภายนอกสถานศึกษา"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2" outlined>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                large
                right
                depressed
                color="primary"
                @click.native="assistantcommitteequalifiedAdd()"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>เพิ่มรายการ
              </v-btn>
            </v-col>
          </v-row>
        </v-card>

        <v-data-table
          color="success"
          :loading="loading"
          :headers="showHeaders"
          :items="assistantcommitteequalifieds"
          :search="search"
        >
          <template v-slot:top>
            <div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </div>
            <div>
              <v-select
                v-model="selectedHeaders"
                :items="headers"
                label="เลือกคอลัมน์ที่ต้องการแสดง"
                multiple
                outlined
                return-object
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 6">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span v-if="index === 6" class="grey--text caption"
                    >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
                  >
                </template>
              </v-select>
            </div>
          </template>
          <template v-slot:item.assistantcommitteeQualifiedType="{ item }">
            <div>
              <span
                class="warning--text"
                v-if="item.assistantcommitteeQualifiedType === 'outside'"
              >
                ภายนอก/เกษียณอายุ
              </span>
              <span
                class="success--text"
                v-else-if="item.assistantcommitteeQualifiedType === 'inside'"
              >
                ภายใน สอศ.
              </span>
            </div>
          </template>

          <template v-slot:item.assistantcommitteequalified_salary="{ item }">
            {{
              Number(item.assistantcommitteequalified_salary).toLocaleString()
            }}
          </template>

          <template v-slot:item.assistantcommitteequalified_budget="{ item }">
            <span v-if="item.assistantcommitteequalified_budget === 'budget'"
              >จากงบประมาณ</span
            >
            <span
              v-else-if="item.assistantcommitteequalified_budget === 'income'"
              >จากรายได้สถานศึกษา</span
            >
          </template>
          <template v-slot:item.assistantcommitteeQualifiedFile="{ item }">
            <div v-if="item.assistantcommitteeQualifiedType === 'outside'">
              <div
                v-if="String(item.assistantcommitteeQualifiedFile).length > 4"
              >
                <v-btn
                  @click="
                    viewpdfassistantcommitteeQualifiedFile(
                      item.assistantcommitteeQualifiedFile
                    )
                  "
                  fab
                  small
                  color="info"
                >
                  <v-icon>mdi-magnify</v-icon></v-btn
                >
              </div>
              <div v-else>
                <v-icon color="warning">mdi-alert</v-icon>
              </div>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              color="yellow"
              @click.stop="
                assistantcommitteequalifiedEdit(
                  item.assistantcommitteeQualifiedIDcard
                )
              "
            >
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:item.action_s="{ item }">
            <v-icon
              color="red"
              @click.stop="
                assistantcommitteequalifiedDelete(
                  item.assistantcommitteeQualifiedCollege,
                  item.assistantcommitteeQualifiedIDcard
                )
              "
            >
              mdi-delete
            </v-icon>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!--addassistantcommitteequalifieddialog  -->
      <v-layout row justify-center>
        <v-dialog
          v-model="addassistantcommitteequalifieddialog"
          persistent
          max-width="60%"
          overlay-opacity="0.6"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-clipboard-text"
              title="เพิ่มข้อมูล การเสนอชื่อผู้ทรงคุณวุฒิอื่นจากภายนอกสถานศึกษา"
              class="px-5 py-3"
            ></base-material-card>
            <v-form ref="addassistantcommitteequalifiedform" lazy-validation>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex md12>
                    <v-alert
                      border="top"
                      colored-border
                      color="#34CDC1"
                      elevation="2"
                      class="pa-2"
                    >
                      <div class="text-right mt-2">
                        <v-btn outlined color="info" @click="viewPDFEx()">
                          <v-icon>mdi-magnify</v-icon> แนวปฏิบัติฯ</v-btn
                        >
                      </div>
                      <div class="text-center">
                        <v-radio-group
                          v-model="assistantcommitteeQualifiedType"
                          row
                        >
                          <v-radio value="inside">
                            <template v-slot:label>
                              <div>
                                <strong class="success--text"
                                  >ข้าราชการ สังกัด สำนักงานคณะกรรมการอาชีวศึกษา
                                  ที่อยู่ใกล้เคียง</strong
                                >
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="outside">
                            <template v-slot:label>
                              <div>
                                <strong class="primary--text"
                                  >บุคคลภายนอกสถานศึกษา</strong
                                >
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </div>
                      <div class="text-center">
                        <v-text-field
                          v-model="
                            addassistantcommitteequalified.assistantcommitteeQualifiedIDcard
                          "
                          @input="validateInput()"
                          label="ระบุหมายเลขบัตรประชาชนของผู้ทรงคุณวุฒิ"
                          append-icon="mdi-magnify"
                          :rules="[
                            v => !!v || 'This field is required',
                            v => (!!v && v.length == 13) || '13  ID Card',
                            v =>
                              checkIdcard == false ||
                              'ต้องเป็นบุคคลภายนอกสถานศึกษา'
                          ]"
                        ></v-text-field>
                      </div>
                      <div
                        v-if="
                          validateInputcheck === 'true' &&
                            addassistantcommitteequalified.assistantcommitteeQualifiedIDcard &&
                            assistantcommitteeQualifiedType === 'inside'
                        "
                      >
                        <div>
                          ชื่อ-นามสกุล {{ personnel_temporarys.frist_names }}
                        </div>
                        <div>
                          สังกัด {{ personnel_temporarys.college_name }}
                        </div>
                        <div>
                          วิทยะฐานะ {{ personnel_temporarys.rang_name }}
                        </div>
                        <div>
                          วุฒิ
                          {{
                            personnel_temporarys.ed_abb +
                              ":" +
                              personnel_temporarys.ed_name
                          }}
                        </div>

                        <div
                          v-if="personnel_temporarys.rang_level <= 2"
                          class="red--text"
                        >
                          <v-icon>mdi-alert</v-icon> ผู้ทรงคุณวุฒิ
                          หากเป็นข้าราชการครู ต้องเป็นผู้ทรงคุณวุฒิภายนอก
                          สถานศึกษาใกล้เคียง ระดับ คศ.3 ขึ้นไป
                        </div>

                        <div v-if="personnel_temporarys.user_status ==='director'" class="red--text font-weight-bold">
                            <v-icon color="error" size="30">mdi-alert</v-icon>ไม่สามารถเสนอผู้อำนวยการสถานศึกษาเป็นผู้ทรงคุณวุฒิได้
                         </div>
                      </div>
                    </v-alert>
                  </v-flex>
                  <v-flex
                    md12
                    v-if="assistantcommitteeQualifiedType === 'outside'"
                  >
                    <v-alert
                      border="top"
                      colored-border
                      color="#34CDC1"
                      elevation="2"
                      class="pa-2"
                    >
                      <v-row no-gutters>
                        <v-col cols="12" md="3" class="pa-1">
                          <v-text-field
                            @input="validateInput()"
                            outlined
                            v-model="
                              addassistantcommitteequalified.assistantcommitteeQualifiedIDcard
                            "
                            label="รหัสบัตรประชาชน"
                            :rules="[
                              v => !!v || 'This field is required',
                              v => (!!v && v.length == 13) || '13  ID Card'
                            ]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2" class="pa-1">
                          <v-autocomplete
                            outlined
                            v-model="
                              addassistantcommitteequalified.assistantcommitteeQualifiedTitles
                            "
                            label="คำนำหน้าชื่อ"
                            :rules="[v => !!v || '']"
                            :items="prefixs"
                            item-text="prefix_name"
                            item-value="prefix_name"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="4" class="pa-1">
                          <v-text-field
                            outlined
                            v-model="
                              addassistantcommitteequalified.assistantcommitteeQualifiedFristname
                            "
                            label="ชื่อ"
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3" class="pa-1">
                          <v-text-field
                            outlined
                            v-model="
                              addassistantcommitteequalified.assistantcommitteeQualifiedLastname
                            "
                            label="นามสกุล"
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4" class="pa-1">
                          <v-text-field
                            outlined
                            v-model="
                              addassistantcommitteequalified.assistantcommitteeQualifiedEDSub
                            "
                            maxlength="7"
                            label="วุฒิ ป.ตรี ตัวอย่าง: บธ.บ."
                            :rules="[v => !!v || '']"
                            counter
                            hint="ระบุเป็นตัวย่อ"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="8" class="pa-1">
                          <v-text-field
                            outlined
                            v-model="
                              addassistantcommitteequalified.assistantcommitteeQualifiedED
                            "
                            label="ป.ตรี สาขาวิชาเอก ตัวอย่างข้อมูล ระบุ: ระบบสารสนเทศทางคอมพิวเตอร์"
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4" class="pa-1">
                          <v-text-field
                            outlined
                            v-model="
                              addassistantcommitteequalified.assistantcommitteeQualifiedEDMasterSub
                            "
                            maxlength="7"
                            label="วุฒิ ป.โท ตัวอย่าง: วท.ม."
                            counter
                            hint="ระบุเป็นตัวย่อ"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="8" class="pa-1">
                          <v-text-field
                            outlined
                            v-model="
                              addassistantcommitteequalified.assistantcommitteeQualifiedEDMaster
                            "
                            label="ป.โท สาขาวิชาเอก ตัวอย่างข้อมูล ระบุ: เทคโนโลยีอินเทอร์เน็ตและสารสนเทศ"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4" class="pa-1">
                          <v-text-field
                            outlined
                            v-model="
                              addassistantcommitteequalified.assistantcommitteeQualifiedEDDoctoralSub
                            "
                            maxlength="7"
                            label="วุฒิ ป.เอก ตัวอย่าง: ปร.ด."
                            counter
                            hint="ระบุเป็นตัวย่อ"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="8" class="pa-1">
                          <v-text-field
                            outlined
                            v-model="
                              addassistantcommitteequalified.assistantcommitteeQualifiedEDDoctoral
                            "
                            label="ป.เอก สาขาวิชาเอก ตัวอย่างข้อมูล ระบุ: วิศวกรรมคอมพิวเตอร์"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12" class="pa-1">
                          <v-text-field
                            outlined
                            v-model="
                              addassistantcommitteequalified.assistantcommitteeQualifiedPosition
                            "
                            label="ตำแหน่งปัจจุบัน : ข้าราชการบำนาญ"
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12" class="pa-1">
                          <v-text-field
                            outlined
                            v-model="
                              addassistantcommitteequalified.assistantcommitteeQualifiedETC
                            "
                            label="ตำแหน่งสุดท้ายก่อนได้รับการเสนอรายชื่อคณะกรรมการ : ผู้อำนวยการวิทยาลัยการอาชีพ ข."
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12" class="pa-1">
                          <v-file-input
                            v-model="assistantcommitteeQualifiedFile"
                            accept=".pdf"
                            name="assistantcommitteeQualifiedFile"
                            color="deep-purple accent-4"
                            counter
                            label="แนบไฟล์เอกสารยืนยันตัวตน .pdf"
                            placeholder="เอกสารยืนยันตัวตน"
                            outlined
                            :show-size="1000"
                            :rules="rules"
                          >
                            <template v-slot:selection="{ index, text }">
                              <v-chip
                                v-if="index < 2"
                                color="deep-purple accent-4"
                                dark
                                label
                                small
                              >
                                {{ text }}
                              </v-chip>

                              <span
                                v-else-if="index === 2"
                                class="text-overline grey--text text--darken-3 mx-2"
                              >
                                +{{ files.length - 2 }} File(s)
                              </span>
                            </template>
                          </v-file-input>
                        </v-col>
                      </v-row>
                      <div class="font-weight-bold red--text">
                        ***หมายเหตุ กรอกวุฒิการศึกษาที่สำเร็จในระดับปริญาญาโท
                        ปริญญาเอก (ถ้ามี)
                      </div>
                    </v-alert>
                  </v-flex>
                </v-layout>
                <v-spacer></v-spacer>
                <hr />
                <v-row>
                  <v-col cols="12" lg="12" class="text-right">
                    <div v-if="assistantcommitteeQualifiedType === 'inside'">
                      <v-btn
                        v-if="
                          validateInputcheck === 'true' &&
                            personnel_temporarys.rang_level > 2 && personnel_temporarys.user_status !='director'
                        "
                        color="success"
                        @click.stop="addassistantcommitteequalifiedSubmit()"
                        rounded
                      >
                        <v-icon dark>mdi-content-save</v-icon
                        >&nbsp;&nbsp;บันทึก</v-btn
                      >
                    </div>
                    <div
                      v-else-if="assistantcommitteeQualifiedType === 'outside'"
                    >
                      <v-btn
                        v-if="
                          validateInputcheck === 'true' && checkIdcard === false
                        "
                        color="success"
                        @click.stop="addassistantcommitteequalifiedSubmit()"
                        rounded
                      >
                        <v-icon dark>mdi-content-save</v-icon
                        >&nbsp;&nbsp;บันทึก</v-btn
                      >
                    </div>

                    <v-btn
                      color="gray"
                      fab
                      @click.stop="addassistantcommitteequalifieddialog = false"
                      rounded
                    >
                      <v-icon dark>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model searchassistantcommitteequalifieddialog -->
      <v-layout>
        <v-dialog
          v-model="searchassistantcommitteequalifieddialog"
          persistent
          max-width="50%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="success"
              icon="mdi-account-check"
              title="ค้นพบข้อมูล"
              class="px-5 py-3 "
            ></base-material-card>
            <v-card-text class="">
              <v-form
                ref="searchassistantcommitteequalifieddialogform"
                lazy-validation
              >
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      ยืนยันการนำเข้าข้อมูล :
                      <v-list>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-account-check
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title
                              >รหัสบัตรประชาชน</v-list-item-title
                            >
                            <v-list-item-subtitle>{{
                              personnel_temporarys.id_card
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider inset></v-divider>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-account-card-details
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>ชื่อ-นามสกุล</v-list-item-title>
                            <v-list-item-subtitle
                              >{{ personnel_temporarys.title_s
                              }}{{ personnel_temporarys.frist_name }}
                              {{ personnel_temporarys.last_name }}

                              เลขที่ตำแหน่ง
                              {{ personnel_temporarys.id_position }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>

                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-map-marker
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>ปัจจุบัน</v-list-item-title>
                            <v-list-item-subtitle>{{
                              personnel_temporarys.college_name
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                rounded
                large
                @click.stop="searchassistantcommitteequalifieddialog = false"
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                rounded
                large
                color="green"
                @click.stop="searchassistantcommitteequalifieddialogSubmit()"
                dark
              >
                <v-icon dark>mdi-content-save-settings</v-icon
                >&nbsp;ยืนยันการนำเข้าข้อมูล
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deleteassistantcommitteequalifieddialog -->
      <v-layout>
        <v-dialog
          v-model="deleteassistantcommitteequalifieddialog"
          persistent
          max-width="40%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูล"
              class="px-5 py-3 "
            ></base-material-card>
            <v-card-text class="">
              <v-form
                ref="deleteassistantcommitteequalifiedform"
                lazy-validation
              >
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      ยืนยันการลบข้อมูล :
                      <h3>
                        {{
                          editassistantcommitteequalified.assistantcommitteeQualifiedIDcard
                        }}
                      </h3>
                    </v-flex>
                    <v-flex xs12 md6
                      >{{
                        editassistantcommitteequalified.assistantcommitteeQualifiedTitles
                      }}{{
                        editassistantcommitteequalified.assistantcommitteeQualifiedFristname
                      }}
                      {{
                        editassistantcommitteequalified.assistantcommitteeQualifiedLastname
                      }}</v-flex
                    >
                    <v-flex xs12 md6></v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="deleteassistantcommitteequalifieddialog = false"
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="red darken-3"
                @click.stop="deleteassistantcommitteequalifiedubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editassistantcommitteequalifieddialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="editassistantcommitteequalifieddialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูล"
              class="px-5 py-3 "
            ></base-material-card>
            <v-card-text>
              <v-form ref="editassistantcommitteequalifiedform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-alert
                        border="top"
                        colored-border
                        color="#34CDC1"
                        elevation="2"
                        class="pa-2"
                      >
                        <v-row no-gutters>
                          <v-col cols="12" md="3" class="pa-1">
                            <v-text-field
                              @input="validateInput()"
                              outlined
                              v-model="
                                editassistantcommitteequalified.assistantcommitteeQualifiedIDcard
                              "
                              label="รหัสบัตรประชาชน"
                              :rules="[
                                v => !!v || 'This field is required',
                                v => (!!v && v.length == 13) || '13  ID Card'
                              ]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="2" class="pa-1">
                            <v-text-field
                              outlined
                              v-model="
                                editassistantcommitteequalified.assistantcommitteeQualifiedTitles
                              "
                              label="คำนำหน้าชื่อ"
                              :rules="[v => !!v || '']"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4" class="pa-1">
                            <v-text-field
                              outlined
                              v-model="
                                editassistantcommitteequalified.assistantcommitteeQualifiedFristname
                              "
                              label="ชื่อ"
                              :rules="[v => !!v || '']"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="3" class="pa-1">
                            <v-text-field
                              outlined
                              v-model="
                                editassistantcommitteequalified.assistantcommitteeQualifiedLastname
                              "
                              label="นามสกุล"
                              :rules="[v => !!v || '']"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="4" class="pa-1">
                            <v-text-field
                              outlined
                              v-model="
                                editassistantcommitteequalified.assistantcommitteeQualifiedEDSub
                              "
                              maxlength="7"
                              label="วุฒิ ป.ตรี ตัวอย่าง: บธ.บ."
                              :rules="[v => !!v || '']"
                              counter
                              hint="ระบุเป็นตัวย่อ"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="8" class="pa-1">
                            <v-text-field
                              outlined
                              v-model="
                                editassistantcommitteequalified.assistantcommitteeQualifiedED
                              "
                              label="ป.ตรี สาขาวิชาเอก ตัวอย่างข้อมูล ระบุ: ระบบสารสนเทศทางคอมพิวเตอร์"
                              :rules="[v => !!v || '']"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="4" class="pa-1">
                            <v-text-field
                              outlined
                              v-model="
                                editassistantcommitteequalified.assistantcommitteeQualifiedEDMasterSub
                              "
                              maxlength="7"
                              label="วุฒิ ป.โท ตัวอย่าง: วท.ม."
                              counter
                              hint="ระบุเป็นตัวย่อ"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="8" class="pa-1">
                            <v-text-field
                              outlined
                              v-model="
                                editassistantcommitteequalified.assistantcommitteeQualifiedEDMaster
                              "
                              label="ป.โท สาขาวิชาเอก ตัวอย่างข้อมูล ระบุ: เทคโนโลยีอินเทอร์เน็ตและสารสนเทศ"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="4" class="pa-1">
                            <v-text-field
                              outlined
                              v-model="
                                editassistantcommitteequalified.assistantcommitteeQualifiedEDDoctoralSub
                              "
                              maxlength="7"
                              label="วุฒิ ป.เอก ตัวอย่าง: ปร.ด."
                              counter
                              hint="ระบุเป็นตัวย่อ"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="8" class="pa-1">
                            <v-text-field
                              outlined
                              v-model="
                                editassistantcommitteequalified.assistantcommitteeQualifiedEDDoctoral
                              "
                              label="ป.เอก สาขาวิชาเอก ตัวอย่างข้อมูล ระบุ: วิศวกรรมคอมพิวเตอร์"
                            ></v-text-field>
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                            class="pa-1"
                            v-if="
                              editassistantcommitteequalified.assistantcommitteeQualifiedType ===
                                'outside'
                            "
                          >
                            <v-text-field
                              outlined
                              v-model="
                                editassistantcommitteequalified.assistantcommitteeQualifiedPosition
                              "
                              label="ตำแหน่งปัจจุบัน : ตัวอย่าง เช่น ข้าราชการบำนาญ / รองผู้อำนวยการ"
                              :rules="[v => !!v || '']"
                            ></v-text-field>
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                            class="pa-1"
                            v-if="
                              editassistantcommitteequalified.assistantcommitteeQualifiedType ===
                                'outside'
                            "
                          >
                            <v-text-field
                              outlined
                              v-model="
                                editassistantcommitteequalified.assistantcommitteeQualifiedETC
                              "
                              label="ตำแหน่งสุดท้ายก่อนได้รับการเสนอรายชื่อคณะกรรมการ : ผู้อำนวยการวิทยาลัยการอาชีพ ข."
                            ></v-text-field>
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                            class="pa-1 text-center"
                            v-if="
                              editassistantcommitteequalified.assistantcommitteeQualifiedFile &&
                                editassistantcommitteequalified.assistantcommitteeQualifiedType ===
                                  'outside'
                            "
                          >
                            <v-btn
                              @click="
                                viewpdfassistantcommitteeQualifiedFile(
                                  editassistantcommitteequalified.assistantcommitteeQualifiedFile
                                )
                              "
                              color="info"
                              outlined
                            >
                              เอกสารยืนยันตัวตน
                            </v-btn>
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                            class="pa-1"
                            v-if="
                              editassistantcommitteequalified.assistantcommitteeQualifiedType ===
                                'outside'
                            "
                          >
                            <v-file-input
                              v-model="assistantcommitteeQualifiedFile"
                              accept=".pdf"
                              name="assistantcommitteeQualifiedFile"
                              color="deep-purple accent-4"
                              counter
                              label="แนบไฟล์เอกสารยืนยันตัวตน .pdf"
                              placeholder="เอกสารยืนยันตัวตน"
                              outlined
                              :show-size="1000"
                              :rules="rules"
                            >
                              <template v-slot:selection="{ index, text }">
                                <v-chip
                                  v-if="index < 2"
                                  color="deep-purple accent-4"
                                  dark
                                  label
                                  small
                                >
                                  {{ text }}
                                </v-chip>

                                <span
                                  v-else-if="index === 2"
                                  class="text-overline grey--text text--darken-3 mx-2"
                                >
                                  +{{ files.length - 2 }} File(s)
                                </span>
                              </template>
                            </v-file-input>
                          </v-col>
                        </v-row>
                        <div class="font-weight-bold red--text">
                          ***หมายเหตุ กรอกวุฒิการศึกษาที่สำเร็จในระดับปริญาญาโท
                          ปริญญาเอก (ถ้ามี)
                        </div>
                      </v-alert>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="editassistantcommitteequalifieddialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editassistantcommitteequalifiedSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;แก้ไขข้อมูล
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <v-layout row justify-center>
        <v-dialog v-model="pdfFileEx" max-width="80%">
          <v-card class="" elevation="2">
            <embed src="/docEx/CommitteeEx.pdf" width="100%" height="700px" />
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdfCommitteeQfdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdfCommitteeQfdialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HRCommitteeQf/' + pdfFiles"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        multi-line
        vertical
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <br />
        {{ snackbar.text }}
        <v-btn dark @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import AssisstantBar from "../../components/college/assisstantBar.vue";

export default {
  data() {
    return {
      ApiKey: "HRvec2021",
      loading: true,
      updateImageDialog: false,
      search: "",
      addassistantcommitteequalifieddialog: false,
      editassistantcommitteequalifieddialog: false,
      deleteassistantcommitteequalifieddialog: false,
      searchassistantcommitteequalifieddialog: false,
      showimagedialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      currentPK: null,
      headers: [],
      selectedHeaders: [],
      headersMap: [
        {
          text: "รหัส",
          align: "center",
          value: "assistantcommitteeQualifiedIDcard"
        },
        {
          text: "ประเภท",
          align: "center",
          value: "assistantcommitteeQualifiedType"
        },
        {
          text: "คำนำหน้าชื่อ",
          align: "center",
          value: "assistantcommitteeQualifiedTitles"
        },
        {
          text: "ชื่อ",
          align: "center",
          value: "assistantcommitteeQualifiedFristname"
        },
        {
          text: "นามสกุล",
          align: "center",
          value: "assistantcommitteeQualifiedLastname"
        },
        {
          text: "การศึกษา",
          align: "center",
          value: "assistantcommitteeQualifiedED"
        },
        {
          text: "ตำแหน่ง",
          align: "center",
          value: "assistantcommitteeQualifiedPosition"
        },
        {
          text: "วันที่ข้อมูล",
          align: "center",
          value: "assistantcommitteeQualifiedDateTime"
        },
        {
          text: "เอกสารแนบ",
          align: "center",
          value: "assistantcommitteeQualifiedFile"
        },

        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        {
          text: "ลบ",
          align: "center",
          value: "action_s",
          icon: "mdi-delete-forever"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      pagination: {},
      assistantcommitteequalified: [],
      addassistantcommitteequalified: {},
      editassistantcommitteequalified: [],
      assistantcommitteequalifieds: [],
      assistantcommitteequalified_sub: [],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      colleges: {},
      provinces: [],
      prefectures: [],
      regions: [],
      region_ena: true,
      rate_work_course_stds: [],
      type_personnel: [
        { text: "ลูกจ้างประจำ", value: "permanent" },
        { text: "พนักงานราชการ", value: "government" },
        { text: "ครูอัตราจ้าง", value: "temporary" },
        { text: "ลูกจ้างชั่วคราว", value: "temporary_job" }
      ],
      budget_type: [
        { text: "จากงบประมาณ", value: "budget" },
        { text: "จากรายได้สถานศึกษา", value: "income" }
      ],
      assistantcommitteequalifiedsCounts: [],
      personnel_temporary: [],
      personnel_temporarys: [],
      searchadd_temporary: {},
      user: [],
      workgroups: [],
      assistantcommitteeQualifiedType: "inside",
      search_idcard: "",
      checkIdcard: [],
      validateInputcheck: [],
      prefixs: [],
      pdfFileEx: false,
      assistantcommitteeQualifiedFile: null,
      rules: [
        value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB",
        value => !!value || ""
      ],
      pdfCommitteeQfdialog: false,
      pdfFiles: []
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },
  async mounted() {
    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
    let result = await this.$http.post("college.php", {
      ApiKey: this.ApiKey,
      college_code: userSession.user_code
    });
    this.user = result.data;
    this.workgroupQuery();
    this.assistantcommitteequalifiedsQueryAll();
    this.rate_work_course_stdsQueryAll();
    this.assistantcommitteequalifiedsCount();
    this.prefixQuery();
  },
  methods: {
    async viewpdfassistantcommitteeQualifiedFile(
      assistantcommitteeQualifiedFile
    ) {
      this.pdfFiles = assistantcommitteeQualifiedFile;
      this.pdfCommitteeQfdialog = true;
    },
    async viewPDFEx() {
      this.pdfFileEx = true;
    },
    async prefixQuery() {
      let result = await this.$http.post("prefix.php", {
        ApiKey: this.ApiKey
      });
      this.prefixs = result.data;
    },
    async searchIDcard() {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: this.addassistantcommitteequalified
          .assistantcommitteeQualifiedIDcard
      });
      this.personnel_temporarys = result.data;

      if (this.user.college_code == this.personnel_temporarys.college_code) {
        this.checkIdcard = true;
      } else {
        this.checkIdcard = false;
      }
    },

    async validateInput() {
      const maxLength = 13;
      const regex = /^[0-9]\d*$/;
      let event = this.addassistantcommitteequalified
        .assistantcommitteeQualifiedIDcard;
      let value = this.addassistantcommitteequalified
        .assistantcommitteeQualifiedIDcard;
      let i, sum;
      const char =
        String.fromCharCode(event.keyCode) || String.fromCharCode(event.which);
      if (
        value !== undefined &&
        value.toString().length == maxLength &&
        value.match(regex)
      ) {
        if (value.length != 13) return false;
        for (i = 0, sum = 0; i < 12; i++) {
          sum += parseInt(value.charAt(i)) * (13 - i);
        }
        let mod = sum % 11;
        let check = (11 - mod) % 10;
        if (check == parseInt(value.charAt(12))) {
          this.validateInputcheck = "true";
        }
      } else {
        this.validateInputcheck = "false";
      }
      await this.searchIDcard();
    },
    async workgroupQuery() {
      let result = await this.$http.post("workgroup.php", {
        ApiKey: this.ApiKey
      });
      this.workgroups = result.data;
    },
    async personnel_temporaryQuery() {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: this.addassistantcommitteequalified
          .assistantcommitteeQualifiedIDcard
      });
      this.personnel_temporarys = result.data;
      if (this.personnel_temporarys.id_card) {
        this.searchassistantcommitteequalifieddialog = true;
      } else {
      }
    },
    async rate_work_course_stdsQueryAll() {
      let result = await this.$http.post("rate_work_course_std.php", {
        ApiKey: this.ApiKey
      });
      this.rate_work_course_stds = result.data;
    },
    async assistantcommitteequalifiedsQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("assistantcommitteequalified.php", {
          ApiKey: this.ApiKey,
          assistantcommitteeQualifiedCollege: this.user.college_code
        })
        .finally(() => (this.loading = false));
      this.assistantcommitteequalifieds = result.data;
    },
    async assistantcommitteequalifiedsCount() {
      let result = await this.$http.post("assistantcommitteequalified.php", {
        ApiKey: this.ApiKey,
        count_personnel: "Ok",
        assistantcommitteeQualifiedCollege: this.user.college_code
      });
      this.assistantcommitteequalifiedsCounts = result.data;
    },
    async searchassistantcommitteequalifieddialogSubmit() {
      if (this.$refs.searchassistantcommitteequalifieddialogform.validate()) {
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
        this.searchadd_temporary.ApiKey = this.ApiKey;
        this.searchadd_temporary.assistantcommitteeQualifiedCollege =
          userSession.college_code;
        this.searchadd_temporary.assistantcommitteequalified_m_type = this.personnel_temporarys.user_status;
        this.searchadd_temporary.assistantcommitteeQualifiedIDcard = this.personnel_temporarys.id_card;
        this.searchadd_temporary.assistantcommitteeQualifiedTitles = this.personnel_temporarys.title_s;
        this.searchadd_temporary.assistantcommitteeQualifiedFristname = this.personnel_temporarys.frist_name;
        this.searchadd_temporary.assistantcommitteeQualifiedLastname = this.personnel_temporarys.last_name;
        this.searchadd_temporary.assistantcommitteeQualifiedPosition = this.personnel_temporarys.position_name;
        this.searchadd_temporary.assistantcommitteeQualifiedIDcard_position = this.personnel_temporarys.id_position;
        this.searchadd_temporary.assistantcommitteequalified_level = this.personnel_temporarys.rang_level;
        this.searchadd_temporary.assistantcommitteequalified_rang = this.personnel_temporarys.rang_name;
        this.searchadd_temporary.assistantcommitteequalified_salary = this.personnel_temporarys.salary_s;
        this.searchadd_temporary.assistantcommitteequalified_branch = this.personnel_temporarys.personnel_tem_branch;

        let result = await this.$http.post(
          "assistantcommitteequalified.insert.php",
          this.searchadd_temporary
        );
        if (result.data.status == true) {
          this.assistantcommitteequalified = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.assistantcommitteequalifiedsQueryAll();
          this.assistantcommitteequalifiedsCount();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.addassistantcommitteequalifieddialog = false;
        this.searchassistantcommitteequalifieddialog = false;
      }
    },
    //Add data
    async assistantcommitteequalifiedAdd() {
      this.personnel_temporarys = [];
      this.addassistantcommitteequalified = {};
      this.addassistantcommitteequalifieddialog = true;
      this.workgroupQuery();
    },
    async addassistantcommitteequalifiedSubmit() {
      if (this.$refs.addassistantcommitteequalifiedform.validate()) {
        this.addassistantcommitteequalified.ApiKey = this.ApiKey;
        this.addassistantcommitteequalified.assistantcommitteeQualifiedCollege = this.user.college_code;
        this.addassistantcommitteequalified.assistantcommitteeQualifiedType = this.assistantcommitteeQualifiedType;

        if (this.assistantcommitteeQualifiedType == "inside") {
          this.addassistantcommitteequalified.assistantcommitteeQualifiedIDcard = this.personnel_temporarys.id_card;
          this.addassistantcommitteequalified.assistantcommitteeQualifiedTitles = this.personnel_temporarys.title_s;
          this.addassistantcommitteequalified.assistantcommitteeQualifiedFristname = this.personnel_temporarys.frist_name;
          this.addassistantcommitteequalified.assistantcommitteeQualifiedLastname = this.personnel_temporarys.last_name;

          this.addassistantcommitteequalified.assistantcommitteeQualifiedEDSub = this.personnel_temporarys.ed_abb;
          this.addassistantcommitteequalified.assistantcommitteeQualifiedED = this.personnel_temporarys.ed_name;

          this.addassistantcommitteequalified.assistantcommitteeQualifiedEDMasterSub =
            this.personnel_temporarys.masterEdSub || "-";
          this.addassistantcommitteequalified.assistantcommitteeQualifiedEDMaster =
            this.personnel_temporarys.masterEd || "-";

          this.addassistantcommitteequalified.assistantcommitteeQualifiedEDDoctoralSub =
            this.personnel_temporarys.doctoralEdSub || "-";
          this.addassistantcommitteequalified.assistantcommitteeQualifiedEDDoctoral =
            this.personnel_temporarys.doctoralEd || "-";

          this.addassistantcommitteequalified.assistantcommitteeQualifiedPosition =
            this.personnel_temporarys.position_name +
            " คศ." +
            this.personnel_temporarys.rang_level;
          this.addassistantcommitteequalified.assistantcommitteeQualifiedETC = this.personnel_temporarys.rang_name;
          this.addassistantcommitteequalified.assistantcommitteeQualifiedCollegeMain = this.personnel_temporarys.college_name;
        }

        if (this.assistantcommitteeQualifiedFile != "") {
          let formData = new FormData();
          let filename =
            this.addassistantcommitteequalified
              .assistantcommitteeQualifiedIDcard +
            "" +
            this.user.college_code +
            "" +
            "qf.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.assistantcommitteeQualifiedFile);
          formData.append("filename", "../HRCommitteeQf/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          this.addassistantcommitteequalified.assistantcommitteeQualifiedFile = filename;
        }

        this.addassistantcommitteequalified.assistantcommitteeQualifiedDateTime = new Date();

        let result = await this.$http.post(
          "assistantcommitteequalified.insert.php",
          this.addassistantcommitteequalified
        );

        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.assistantcommitteequalifiedsQueryAll();
          this.personnel_temporarys = [];
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.addassistantcommitteequalifieddialog = false;
      }
    },
    //Edit data
    async assistantcommitteequalifiedEdit(assistantcommitteeQualifiedIDcard) {
      let result = await this.$http.post("assistantcommitteequalified.php", {
        ApiKey: this.ApiKey,
        assistantcommitteeQualifiedIDcard: assistantcommitteeQualifiedIDcard
      });
      this.editassistantcommitteequalified = result.data;
      this.editassistantcommitteequalifieddialog = true;
    },
    async editassistantcommitteequalifiedSubmit() {
      if (this.$refs.editassistantcommitteequalifiedform.validate()) {
        if (this.assistantcommitteeQualifiedFile != "") {
          let resultDel = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRCommitteeQf/" +
              this.editassistantcommitteequalified
                .assistantcommitteeQualifiedFile
          });

          let formData = new FormData();
          let filename =
            this.editassistantcommitteequalified
              .assistantcommitteeQualifiedIDcard +
            "" +
            this.user.college_code +
            "" +
            "qf.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.assistantcommitteeQualifiedFile);
          formData.append("filename", "../HRCommitteeQf/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          this.editassistantcommitteequalified.assistantcommitteeQualifiedFile = filename;
        }

        this.editassistantcommitteequalified.ApiKey = this.ApiKey;
        this.editassistantcommitteequalified.assistantcommitteeQualifiedDateTime = new Date();
        let result = await this.$http.post(
          "assistantcommitteequalified.update.php",
          this.editassistantcommitteequalified
        );

        if (result.data.status == true) {
          this.assistantcommitteequalified = result.data;
          Swal.fire({
            icon: "success",
            title: "แก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.assistantcommitteequalifiedsQueryAll();
          this.assistantcommitteequalifiedsCount();
        } else {
          Swal.fire({
            icon: "warning",
            title: "แก้ไขข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editassistantcommitteequalifieddialog = false;
      }
    },
    //DELETE data
    async assistantcommitteequalifiedDelete(
      assistantcommitteeQualifiedCollege,
      assistantcommitteeQualifiedIDcard
    ) {
      let result = await this.$http.post("assistantcommitteequalified.php", {
        ApiKey: this.ApiKey,
        assistantcommitteeQualifiedCollege: assistantcommitteeQualifiedCollege,
        assistantcommitteeQualifiedIDcard: assistantcommitteeQualifiedIDcard
      });
      this.editassistantcommitteequalified = result.data;
      this.deleteassistantcommitteequalifieddialog = true;
    },
    async deleteassistantcommitteequalifiedubmit() {
      if (this.$refs.deleteassistantcommitteequalifiedform.validate()) {
        this.editassistantcommitteequalified.ApiKey = this.ApiKey;

        let resultDel = await this.$http.post("deletefile.php", {
          ApiKey: this.ApiKey,
          filename:
            "../HRCommitteeQf/" +
            this.editassistantcommitteequalified.assistantcommitteeQualifiedFile
        });

        let result = await this.$http.post(
          "assistantcommitteequalified.delete.php",
          this.editassistantcommitteequalified
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ลบข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.assistantcommitteequalifiedsQueryAll();
          this.assistantcommitteequalifiedsCount();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ลบข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.deleteassistantcommitteequalifieddialog = false;
      }
    }
  },
  computed: {
    color() {
      return "indigo";
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    },
    checktypeselect() {
      return this.assistantcommitteeQualifiedType;
    }
  },
  components: { AssisstantBar },
  watch: {
    checktypeselect() {
      if (
        this.addassistantcommitteequalified.assistantcommitteeQualifiedIDcard ==
        "inside"
      ) {
      } else {
        this.addassistantcommitteequalified.assistantcommitteeQualifiedIDcard =
          "";
      }
    }
  }
};
</script>
<style>
.v-data-table thead th {
  font-size: 16px !important;
}
</style>
